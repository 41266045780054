var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","id":"performancesTable"}},[_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Performances par article")])],1),_c('dot-menu',{attrs:{"isLoading":_vm.loading,"excelButton":{
        excelData: _vm.excelButton.data,
        filename: ("perf-par-article-" + _vm.startDate + "_" + _vm.endDate + ".xlsx"),
        disabled: _vm.exportDisabled,
      }},on:{"exportExcel":_vm.exportExcel},scopedSlots:_vm._u([{key:"extraButtons",fn:function(){return [('Keep this element as usage example' === '')?_c('persist-data-button',{attrs:{"title":"Prévision inventaire sur la sélection","data":_vm.articleIds.join(','),"disabled":_vm.loading || _vm.articleIds.length <= 0 || _vm.articleIds.length > 200,"alt":"La sélection doit être comprise entre 1 et 200 articles","storageKey":"audience/perf-per-article/article-list"},on:{"dataPersisted":_vm.goToForecast}}):_vm._e()]},proxy:true}])})],1),_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{ref:"searchComponentRef",attrs:{"filter":_vm.searchFilter,"items":_vm.search.items,"search-input":_vm.search.text,"hide-no-data":!_vm.search.text,"hide-selected":"","label":"Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)","multiple":"","small-chips":"","solo":""},on:{"update:searchInput":function($event){return _vm.$set(_vm.search, "text", $event)},"update:search-input":function($event){return _vm.$set(_vm.search, "text", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"combobox subheading"},[_vm._v("Taper Entrer pour ajouter la recherche du terme:")]),_c('v-chip',{staticClass:"combobox chip",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search.text)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
      var attrs = ref.attrs;
      var item = ref.item;
      var parent = ref.parent;
      var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeSearchItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
      var index = ref.index;
      var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-spacer')]}}]),model:{value:(_vm.search.model),callback:function ($$v) {_vm.$set(_vm.search, "model", $$v)},expression:"search.model"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.search.recommendationList.length > 0)?_c('span',{style:({ fontSize: '1rem', fontWeight: 'bold' })},[_vm._v("Suggestions:")]):_vm._e(),(_vm.search.recommendationIndex > 0)?_c('span',{style:({ cursor: 'pointer' }),on:{"click":function($event){_vm.search.recommendationIndex--}}},[_vm._v(" ‹ ")]):_vm._e(),(
                _vm.search.recommendationIndex <
                _vm.search.recommendationList.length - 1
              )?_c('span',{style:({ cursor: 'pointer' }),on:{"click":function($event){_vm.search.recommendationIndex++}}},[_vm._v(" › ")]):_vm._e(),_vm._l((_vm.search.recommendationList[
                _vm.search.recommendationIndex
              ]),function(word,i){return _c('v-chip',{key:i,staticClass:"ma-1",attrs:{"label":"","small":""},on:{"click":function($event){return _vm.addSearchItem(word)}}},[_vm._v(" "+_vm._s(word)+" ")])})],2),_c('v-col',{attrs:{"cols":"5"}},[_c('search-type',{attrs:{"store":"audience","anchor":"performancesTable"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('search-column',{attrs:{"columns":['site', 'category', 'redac', 'title', 'url'],"store":"audience","anchor":"performancesTable"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('apply-search',{attrs:{"disabled":_vm.searchDisabled}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('table-stats',{attrs:{"data":_vm.stats}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"page":_vm.pagination.options.page,"pageCount":_vm.pagination.numberOfPages,"headers":_vm.headers,"items":_vm.pagination.items,"options":_vm.pagination.options,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"sort-by":_vm.pagination.options.sortBy,"sort-desc":_vm.pagination.options.sortDesc,"search":_vm.searchListStr,"footer-props":{
        'items-per-page-options': [10, 25, 50],
      },"items-per-page":_vm.pagination.options.itemsPerPage},on:{"update:options":function($event){return _vm.$set(_vm.pagination, "options", $event)}},scopedSlots:_vm._u([{key:"item.adn_post_id",fn:function(ref){
      var item = ref.item;
return [(item.adn_post_id)?_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.openModal(item.adn_post_id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChartLine))])],1):_vm._e()]}},{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.url_path_identifier",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.url_path_identifier}},[(item.url)?_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url_path_identifier))]):_c('span',[_vm._v(_vm._s(item.url_path_identifier))])])]}},{key:"item.meta_description",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.meta_description}},[_vm._v(" "+_vm._s(item.meta_description)+" ")])]}}])})],1),_c('progress-bar',{attrs:{"download":_vm.download}}),_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.dialogModel.dialog),callback:function ($$v) {_vm.$set(_vm.dialogModel, "dialog", $$v)},expression:"dialogModel.dialog"}},[_c('post-performances-modal',{attrs:{"dialogOpen":_vm.dialogModel.dialog,"id":_vm.dialogModel.id,"startDate":_vm.startDate,"endDate":_vm.endDate,"periodType":_vm.periodType}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }