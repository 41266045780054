<template>
  <v-card outlined id="performancesTable">
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="12">Performances par article</v-col>
      </v-row>

      <dot-menu
        :isLoading="loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData: excelButton.data,
          filename: `perf-par-article-${startDate}_${endDate}.xlsx`,
          disabled: exportDisabled,
        }"
      >
        <template v-slot:extraButtons>
          <persist-data-button
            v-if="'Keep this element as usage example' === ''"
            title="Prévision inventaire sur la sélection"
            :data="articleIds.join(',')"
            :disabled="
              loading || articleIds.length <= 0 || articleIds.length > 200
            "
            alt="La sélection doit être comprise entre 1 et 200 articles"
            storageKey="audience/perf-per-article/article-list"
            @dataPersisted="goToForecast"
          />
        </template>
      </dot-menu>
    </v-card-title>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="search.model"
                :filter="searchFilter"
                :items="search.items"
                :search-input.sync="search.text"
                :hide-no-data="!search.text"
                ref="searchComponentRef"
                hide-selected
                label="Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)"
                multiple
                small-chips
                solo
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <span class="combobox subheading"
                      >Taper Entrer pour ajouter la recherche du terme:</span
                    >
                    <v-chip class="combobox chip" label small>
                      {{ search.text }}
                    </v-chip>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2"> {{ item.text }} </span>
                    <v-icon small @click="removeSearchItem(item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
                <template v-slot:item="{ index, item }">
                  <v-chip label small> {{ item.text }} </v-chip>
                  <v-spacer></v-spacer>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <span
                v-if="search.recommendationList.length > 0"
                :style="{ fontSize: '1rem', fontWeight: 'bold' }"
                >Suggestions:</span
              >
              <span
                v-if="search.recommendationIndex > 0"
                @click="search.recommendationIndex--"
                :style="{ cursor: 'pointer' }"
              >
                &lsaquo;
              </span>
              <span
                v-if="
                  search.recommendationIndex <
                  search.recommendationList.length - 1
                "
                @click="search.recommendationIndex++"
                :style="{ cursor: 'pointer' }"
              >
                &rsaquo;
              </span>
              <v-chip
                v-for="(word, i) in search.recommendationList[
                  search.recommendationIndex
                ]"
                :key="i"
                @click="addSearchItem(word)"
                class="ma-1"
                label
                small
              >
                {{ word }}
              </v-chip>
            </v-col>
            <v-col cols="5">
              <search-type store="audience" anchor="performancesTable" />
            </v-col>
            <v-col cols="5">
              <search-column
                :columns="['site', 'category', 'redac', 'title', 'url']"
                store="audience"
                anchor="performancesTable"
              />
            </v-col>
            <v-col cols="2">
              <apply-search :disabled="searchDisabled" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <table-stats :data="stats"></table-stats>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :page="pagination.options.page"
        :pageCount="pagination.numberOfPages"
        :headers="headers"
        :items="pagination.items"
        :options.sync="pagination.options"
        :server-items-length="pagination.total"
        :loading="loading"
        :sort-by="pagination.options.sortBy"
        :sort-desc="pagination.options.sortDesc"
        :search="searchListStr"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="pagination.options.itemsPerPage"
      >
        <template v-slot:item.adn_post_id="{ item }">
          <v-btn
            v-if="item.adn_post_id"
            class="ma-2"
            text
            icon
            @click.native="openModal(item.adn_post_id)"
            ><v-icon>{{ icons.mdiChartLine }}</v-icon></v-btn
          >
        </template>
        <template v-slot:item.title="{ item }">
          <div class="truncateCell" :title="item.title">
            {{ item.title }}
          </div>
        </template>
        <template v-slot:item.url_path_identifier="{ item }">
          <div class="truncateCell" :title="item.url_path_identifier">
            <a :href="item.url" target="_blank" v-if="item.url">{{
              item.url_path_identifier
            }}</a>
            <span v-else>{{ item.url_path_identifier }}</span>
          </div>
        </template>
        <template v-slot:item.meta_description="{ item }">
          <div class="truncateCell" :title="item.meta_description">
            {{ item.meta_description }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <progress-bar :download="download" />
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <post-performances-modal
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        :startDate="startDate"
        :endDate="endDate"
        :periodType="periodType"
      ></post-performances-modal>
    </v-dialog>
  </v-card>
</template>

<style scoped>
.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>
<script>
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import { mdiChartLine } from "@mdi/js";
import {
  computed,
  del,
  onMounted,
  reactive,
  ref,
  set,
  watch,
} from "@vue/composition-api";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { runParallelAsyncs } from "@/utils/async";
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  decodeHtmlEntities,
  formatCurrency,
  formatNumber,
} from "@/utils/formatting";

import ApplySearch from "@/components/common/filters/ApplySearch";
import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import PersistDataButton from "@/components/common/menus/buttons/PersistDataButton";
import PostPerformancesModal from "@/components/audience/perf-per-article/modals/PostPerformances";
import SearchColumn from "@/components/common/filters/SearchColumn";
import SearchType from "@/components/common/filters/SearchType";
import TableStats from "@/components/audience/perf-per-article/cards/TableStatsCard";

export default {
  name: "PerfPerArticle",
  components: {
    ApplySearch,
    DotMenu,
    PersistDataButton,
    PostPerformancesModal,
    ProgressBar,
    SearchColumn,
    SearchType,
    TableStats,
  },
  setup(props, { emit }) {
    const { can, router, store } = useProxy();
    const { axiosGet } = useAxios();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const canSeeAllData =
      can("manage", "audience") || can("manage", "regie_with_ca");

    const isInitialized = ref(false);
    const isOptionsInitialized = ref(false);
    const forceCall = ref(false);
    const loading = ref(false);

    const pagination = reactive({
      numberOfPages: 0,
      total: 0,
      items: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [canSeeAllData ? "ca" : "views"],
        sortDesc: [true],
      },
    });
    const search = reactive({
      text: "",
      model: [],
      items: [],
      recommendationList: [],
      recommendationIndex: 0,
    });
    const download = reactive(download_initial_state());

    const stats = reactive({
      total: 0,
      stats: {},
    });
    const dialogModel = reactive({
      dialog: false,
      id: -1,
    });
    const excelButton = reactive({
      data: new Blob([""], { type: "text/plain" }),
    });

    // =>  METHODS
    const openModal = (postId) => {
      dialogModel.dialog = !dialogModel.dialog;
      dialogModel.id = postId;
    };

    const getSearchRecommendations = async () => {
      try {
        const selectedSitesString = siteList.value
          .filter((site) => sites.value.includes(site.id))
          .reduce((acc, site, index, array) => {
            const siteString = `${site.name} (${site.domain})`;
            return acc + siteString + (index < array.length - 1 ? ", " : "");
          }, "");

        const { data } = await axiosGet("/adn-post/search-recommendations", {
          search: searchListStr.value,
          sites: selectedSitesString,
        });
        const searchRecommendations = data.answer.word_list.filter(
          (word) => !searchList.value.includes(word)
        );
        search.recommendationList.push([
          ...searchList.value,
          ...searchRecommendations,
        ]);
        search.recommendationIndex = search.recommendationList.length - 1;
      } catch (error) {
        console.warn("getSearchRecommendations error", error);
      }
    };

    const goToForecast = () => {
      const route = router.resolve({ name: "regie-forecast" });
      router.push(route);
    };

    const performances_url_params = (forExport = false, forStats = false) => {
      return {
        start_date: startDate.value,
        end_date: endDate.value,
        period_type: periodType.value,
        sites_id: sites.value.join(","),
        categories: categories.value.join(","),
        search_text: searchListStr.value,
        search_column: searchColumn.value,
        search_type: searchType.value,
        ...(forStats
          ? {}
          : {
              page: Math.max(pagination.options.page - 1, 0),
              ...(forExport ? {} : { limit: pagination.options.itemsPerPage }),
              sort_by: pagination.options.sortBy?.[0] ?? "ca",
              sort_desc: pagination.options.sortDesc?.[0] ?? true,
            }),
      };
    };

    const formatResult = (data) => {
      return data.map((item) => {
        const site = siteList.value.find((_site) => _site.id === item.site_id);

        return {
          ...item,
          ...(canSeeAllData && item?.ca !== undefined
            ? {
                ca: formatCurrency(item.ca),
                rpm: formatCurrency((item.ca / item.sessions) * 1000),
              }
            : {}),
          views: formatNumber(item.views),
          ...(canSeeAllData
            ? {
                sessions: formatNumber(item.sessions),
                views_per_session: formatNumber(item.views / item.sessions),
              }
            : {}),
          chars_count: formatNumber(item.chars_count),
          site_name: site?.name ?? "",
          title: decodeHtmlEntities(item.title),
          meta_description: decodeHtmlEntities(item.meta_description),
        };
      });
    };

    const checkDates = () => {
      if (
        !startDate.value ||
        startDate.value.length !== 10 ||
        !endDate.value ||
        endDate.value.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }

      return true;
    };

    const exportExcel = async () => {
      if (!checkDates()) {
        return;
      }

      loading.value = true;
      download.running = true;
      const { data } = await axiosGet(
        "/adn-post/performances/export_xlsx",
        performances_url_params(true),
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      excelButton.data = data;
      loading.value = false;
    };

    const debouncedSearch = debounce(async function () {
      if (pagination.options.page !== 1) {
        pagination.options.page = 1;
        // updateData automatically call through watcher
      } else {
        debouncedGetData();
        getSearchRecommendations();
      }
    }, 500);

    const debouncedGetData = debounce(async function () {
      await getData();
    }, 500);

    const getData = async () => {
      if (
        (applyFilters.value || applySearch.value || forceCall.value) &&
        !loading.value
      ) {
        if (!checkDates()) {
          return;
        }
        loading.value = true;
        store.dispatch("audience/updateStatsForPeriod", {});
        try {
          const { data: performancesData } = await axiosGet(
            "/adn-post/performances",
            performances_url_params()
          );

          pagination.items = formatResult(performancesData.items);
          pagination.total = performancesData.total;
          pagination.numberOfPages =
            performancesData.total / pagination.options.itemsPerPage;

          stats.total = performancesData.total;

          // Doesn't depends on pagination.options (only run on first page):
          if (Math.max(pagination.options.page - 1, 0) === 0) {
            const {
              performancesStatsResponse: { data: performancesStatsData },
              statsResponse: { data: statsData },
            } = await runParallelAsyncs({
              performancesStatsResponse: axiosGet(
                "/adn-post/performances-stats",
                performances_url_params(false, true)
              ),
              statsResponse: axiosGet("/audience/stats", {
                start_date: startDate.value,
                end_date: endDate.value,
                sites: sites.value.join(","),
                period_type: periodType.value,
                categories: categories.value.join(","),
              }),
            });
            stats.stats = performancesStatsData;

            // this.articleIds =
            //   (performancesStatsData.id_list ?? "").split(",") ?? [];
            store.dispatch("audience/updateStatsForPeriod", statsData);
          }
        } catch (error) {
          console.error("error while fetching data");
        }

        loading.value = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        store.dispatch("common/updateApplyFilters", false);
        store.dispatch("common/updateApplySearch", false);
      }
      forceCall.value = false;
    };

    const searchFilter = (item, queryText, itemText) => {
      if (item.header) {
        return false;
      }

      const hasValue = (val) => (val != null ? val : "");
      const text = hasValue(itemText);
      const query = hasValue(queryText);
      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    };

    const addSearchItem = (text) => {
      const newItem = { text };
      if (!search.model.find((item) => item.text === newItem.text)) {
        search.model.push(newItem);
      }
    };

    const removeSearchItem = (item) => {
      // Remove the item from the searchModel without adding it back to the searchItems list
      const index = search.model.indexOf(item);
      if (index > -1) {
        search.model.splice(index, 1);
      }
    };

    // =>  COMPUTED
    const siteList = computed(() => store.getters["common/getSiteList"]);
    const applyFilters = computed(
      () => store.getters["common/getApplyFilters"]
    );
    const applySearch = computed(() => store.getters["common/getApplySearch"]);
    const categories = computed(() => store.getters["audience/getCategories"]);
    const sites = computed(() => store.getters["audience/getSites"]);
    const startDate = computed(() => store.getters["audience/getDates"][0]);
    const endDate = computed(() => store.getters["audience/getDates"][1]);
    const periodType = computed(() => store.getters["audience/getPeriodType"]);
    const searchType = computed(() => store.getters["audience/getSearchType"]);
    const searchColumn = computed(
      () => store.getters["audience/getSearchColumn"]
    );
    const exportDisabled = computed(
      () => loading.value || pagination.items.length === 0
    );
    const searchList = computed(() => search.model.map((item) => item.text));
    const searchListStr = computed(() => searchList.value.join(","));

    // =>  WATCH
    watch(
      () => search.model,
      (val, prev) => {
        if (!isEqual(val, prev)) {
          search.model = val.map((v) => {
            if (typeof v === "string") {
              v = {
                text: v,
              };
            }
            return v;
          });
        }

        if (isInitialized.value) {
          // Only update user changes, not initialization
          addQueryStringParam(
            {
              param: URL_PARAM_NAMES["Search"],
              value: search.model.map((s) => s.text),
              is_multiple: true,
              // is_integer: false,
              // is_boolean: false,
            },
            "performancesTable"
          );
        }
      }
    );

    watch(startDate, () => {
      debouncedGetData();
    });

    watch(endDate, () => {
      debouncedGetData();
    });

    watch(periodType, () => {
      debouncedGetData();
    });

    watch(sites, () => {
      debouncedGetData();
    });

    watch(
      () => pagination.options,
      () => {
        if (isOptionsInitialized.value) {
          // Not on initialization : wait for a real user change
          forceCall.value = true;
        }

        isOptionsInitialized.value = true;
        debouncedGetData();
      }
    );

    watch(applySearch, () => {
      debouncedSearch();
    });

    watch(categories, () => {
      debouncedGetData();
    });

    watch(applyFilters, () => {
      debouncedGetData();
    });

    watch(
      () => download.percentCompleted,
      (newValue) => {
        if (newValue >= 100) {
          setTimeout(() => {
            const initialState = download_initial_state();
            for (const [key, value] of Object.entries(initialState)) {
              set(download, key, value);
            }
            for (const key of Object.keys(download)) {
              if (!(key in initialState)) {
                del(download, key);
              }
            }
          }, 500);
        }
      }
    );

    // =>  HOOKS
    onMounted(async () => {
      await store.dispatch("common/getSiteList");

      const searchListFromUrl = await synchronizeFilterWithQueryString({
        dispatcher: "",
        param: URL_PARAM_NAMES["Search"],
        value: searchList.value,
        is_multiple: true,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });

      if (searchListFromUrl && searchListFromUrl.length > 0) {
        search.model = searchListFromUrl.map((s) => ({ text: s }));
      }

      isInitialized.value = true;
    });

    // =>  DATA
    return {
      icons: {
        mdiChartLine,
      },
      download,
      search,
      searchListStr,
      exportDisabled,
      searchDisabled: loading,
      periodType,
      startDate,
      endDate,
      searchFilter,
      addSearchItem,
      removeSearchItem,
      goToForecast,
      exportExcel,
      articleIds: [],
      loading,
      stats,
      pagination,
      dialogModel,
      openModal,
      excelButton,
      headers: [
        {
          text: "",
          value: "adn_post_id",
          sortable: false,
        },
        {
          text: "Date création",
          align: "start",
          value: "adn_post_creation_date",
        },
        {
          text: "Site",
          value: "site_name",
        },
        {
          text: "Rédac",
          align: "start",
          value: "redac_name",
        },
        {
          text: "Titre",
          value: "title",
          sortable: false,
        },
        {
          text: "URL",
          value: "url_path_identifier",
          sortable: false,
        },
        {
          text: "Méta descr",
          value: "meta_description",
          sortable: false,
        },
        ...(canSeeAllData
          ? [
              {
                text: "CA",
                value: "ca",
              },
            ]
          : []),
        {
          text: "Vues",
          value: "views",
        },
        ...(canSeeAllData
          ? [
              {
                text: "Sessions",
                value: "sessions",
              },
              {
                text: "Vues / Session",
                value: "views_per_session",
                sortable: false,
              },
              {
                text: "RPM",
                value: "rpm",
                sortable: false,
              },
            ]
          : []),
        {
          text: "Nb chars",
          value: "chars_count",
          sortable: false,
        },
        {
          text: "Catégorie",
          value: "category",
          sortable: false,
        },
        {
          text: "New",
          value: "is_new",
          sortable: false,
        },
        {
          text: "Diapo",
          value: "is_diapo",
          sortable: false,
        },
        {
          text: "Typologie",
          value: "ga_typology",
          sortable: false,
        },
        {
          text: "Type",
          value: "content_type",
          sortable: false,
        },
      ],
    };
  },
};
</script>
