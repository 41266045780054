<template>
  <v-btn
    @click="setApplySearch"
    :class="classList"
    :style="styles"
    :outlined="outlined"
    :disabled="disabled"
    :color="color"
  >
    {{ title }}
  </v-btn>
</template>

<script>
import useProxy from "@/hooks/useProxy";

export default {
  name: "ApplySearch",
  props: {
    title: {
      type: String,
      default: "OK",
    },
    classList: {
      type: String,
      default: "pt-7 pb-6 mt-0 my-1",
    },
    styles: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    // =>  METHODS
    const setApplySearch = () => {
      store.dispatch("common/updateApplySearch", true);
    };

    // =>  COMPUTED

    // =>  WATCH

    // =>  HOOKS

    // =>  DATA
    return {
      setApplySearch,
    };
  },
};
</script>

<style></style>
