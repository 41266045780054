<template>
  <v-select
    outlined
    clearable
    v-model="selected"
    :items="items"
    :menu-props="{ maxHeight: '200', zIndex: 13 }"
    label="Type de recherche"
  ></v-select>
</template>

<script>
import isEmpty from "lodash/isEmpty";

import { computed, onMounted, reactive, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "SearchType";
const DEFAULT_VALUE = "contains";

/**
 * Call this component specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: COMPONENT_NAME,
  props: {
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["audience", "regie"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getSearchType",
    },
    storeUpdater: {
      type: String,
      default: "updateSearchType",
    },
    anchor: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const page = reactive({
      isInitialized: false,
    });

    // =>  METHODS

    // =>  COMPUTED
    const selected = computed({
      get() {
        const storedValue =
          store.getters[`${props.store}/${props.storeGetter}`];

        return isEmpty(storedValue) ? DEFAULT_VALUE : storedValue;
      },
      set(e) {
        store.dispatch(`${props.store}/${props.storeUpdater}`, e);
      },
    });

    // =>  WATCH
    watch(selected, (newValue) => {
      if (page.isInitialized) {
        // Only update user changes, not initialization
        addQueryStringParam(
          {
            param: URL_PARAM_NAMES[COMPONENT_NAME],
            value: newValue,
            // is_multiple: false,
            // is_integer: false,
            // is_boolean: false,
          },
          props.anchor
        );
      }
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: `${props.store}/${props.storeUpdater}`,
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: selected.value,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });

      page.isInitialized = true;
    });

    // =>  DATA
    return {
      selected,
      items: [
        { text: "Valeur exacte", value: "exact-match" },
        { text: "Commençant par", value: "starts-with" },
        { text: "Mot commençant par", value: "word-starts-with" },
        { text: "Contenant", value: DEFAULT_VALUE },
      ],
    };
  },
};
</script>

<style scoped></style>
