<template>
  <v-card v-if="Object.keys(stats).length > 0" outlined>
    <v-card-text>
      <v-row v-if="canSeeAllData">
        <v-col cols="6">
          <span class="font-weight-bold">Articles : </span>{{ stats.total }}
        </v-col>
        <v-col cols="6">
          <span class="font-weight-bold">Ratio : </span
          >{{ stats.audience_ratio }}
        </v-col>
      </v-row>
      <v-row v-if="canSeeAllData">
        <v-col cols="6">
          <span class="font-weight-bold">Sessions : </span>{{ stats.sessions }}
        </v-col>
        <v-col cols="3">
          <span class="font-weight-bold">CA : </span>{{ stats.ca }}
        </v-col>
        <v-col cols="3">
          <span class="font-weight-bold">RPM : </span>{{ stats.rpm }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <span class="font-weight-bold">Vues : </span>{{ stats.views }}
        </v-col>
        <v-col cols="6">
          <span v-if="canSeeAllData">
            <span class="font-weight-bold">Vues / Sessions : </span
            >{{ stats.views_per_session }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "@/utils/formatting";

export default {
  name: "TableStats",
  props: {
    data: {
      type: Object,
      default: () => ({
        total: 0,
        ca: 0,
        sessions: 0,
        rpm: 0,
        views: 0,
        views_per_session: 0,
        audience_ratio: 0,
      }),
    },
  },
  data() {
    return {
      canSeeAllData:
        this.$can("manage", "audience") || this.$can("manage", "regie_with_ca"),
      stats: {},
    };
  },
  methods: {
    formatStats() {
      const {
        stats: { ca = 0, sessions = 0, views = 0 },
        total = 0,
      } = this.data;

      return {
        ...(this.canSeeAllData
          ? {
              ca: formatCurrency(ca),
              rpm: formatCurrency((ca / Math.max(sessions, 1)) * 1000),
              sessions: formatNumber(sessions),
              views_per_session: formatNumber(views / Math.max(sessions, 1)),
            }
          : {}),
        views: formatNumber(views),
        total: formatNumber(total),
        audience_ratio: formatPercent(
          sessions / Math.max(this.statsForPeriod?.sessions ?? 0, 1)
        ),
      };
    },
  },
  computed: {
    statsForPeriod() {
      return this.$store.getters["audience/getStatsForPeriod"];
    },
  },
  watch: {
    ["data.total"]() {
      this.stats = this.formatStats();
    },
    ["data.stats"]() {
      this.stats = this.formatStats();
    },
    statsForPeriod() {
      this.stats = this.formatStats();
    },
  },
};
</script>
